
<template>
    <div>
      <div class="ranking">{{rank + 1 + index}}.</div>
      <div v-if="item.certified_partner_id" :data-partner-id="item.partner_id" class="name">{{item.first_name}} {{item.last_name}}</div>
      <div v-else :data-partner-id="item.partner_id" class="name">{{ item.alias ? item.alias : item.company_name }} </div>
      <div class="champion"><div :class="{ 'champion-logo': item.champion }"></div></div>
      <div class="diamond"><div :class="{ 'diamond-logo': item.diamond }"></div></div>
      <div class="award"><div class="award-logo">{{item.award}}</div></div>
      <div class="compliance" :class="getItemComplianceClass(item.compliance)">{{formatCompliance(item.compliance)}}</div>
      <div class="revenue-today">&euro;{{formatFee(item.fee_partner_today)}}</div>
      <div class="revenue-week">&euro;{{formatFee(item.fee_partner_this_week)}}</div>
      <div class="revenue-quarter">&euro;{{formatFee(item.fee_partner_this_quarter)}}</div>
    </div>
</template>

<script>
// import shared from '../shared/shared.js';
// import { useStore } from 'vuex'
import Mixin from '../shared/mixin';

export default {
  name: 'PartnerList',
  props: [
    'item','rank','toggle','index'
  ],
  mixins: [Mixin],
  setup() {
  }
};

</script>