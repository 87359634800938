import { createStore } from "vuex";
import shared from "../shared/shared.js";
// import mixin from "../shared/mixin";

export default createStore({
  state: {
    summary_partners: [],
    summary_teams: [],
    summary_advertisers: [],
    advertisers: [],
    details: [],
    recentDetails: [],
    compliance: [],
    teams: [],
    targets: [],
    contacts: [],
    feeds: [],
    current_time: null,
    date: null,
    reviews: [],
    charts: [],
    welcome: "Welcome to the store",
    access_token: null,
    loaded: false,
    inloop: 0,
    theme: "improvers",
    temp_sales: [],
    revenueSummary: {},
    statsCherries: [],
    qp: [],
    awards: [],
    leagues: [],
    champions_league: null,
    champions_league_data: [],
    champions_league_compliance: [],
    champions_league_reviews: [],
    diamond_league: null,
    diamond_league_data: [],
    diamond_league_compliance: [],
    diamond_league_reviews: [],
    qualification: null,
    qualification_data: [],
    qualification_compliance: [],
    qualification_reviews: [],
    league_exclusions: [],
  },
  mutations: {
    addCurrentSale(state, sale) {
      // console.log('add', sale)
      // state.details.push(sale);
      // console.log('shifted')
      state.details.unshift(sale);
    },
    updateCurrentSale(state, payload) {
      // console.log('update', payload.sale, payload)
      state.details[payload.index] = payload.sale;
    },
    addTempSale(state, sale) {
      state.temp_sales.push(sale);
    },
    addContact(state, contact) {
      state.contacts.push(contact);
    },
    updateContact(state, payload) {
      state.contacts[payload.index] = payload.contact;
    },
    setContacts(state, contacts) {
      state.contacts = contacts;
    },
    setSummaryPartners(state, summary) {
      state.summary_partners = summary;
    },
    setSummaryAdvertisers(state, advertisers) {
      // console.log('summary_advertisers', advertisers)
      state.summary_advertisers = advertisers;
    },
    setSummaryTeams(state, teams) {
      state.summary_teams = teams;
    },
    setDetails(state, details) {
      // state.details = details
      state.details = details; //details.filter((el) => el.certified_partner_id == null).sort(getters.sortSaleClosed);
    },
    setCompliance(state, compliance) {
      state.compliance = compliance;
    },
    setTargets(state, targets) {
      state.targets = targets;
    },
    setDate(state, date) {
      state.date = date;
    },
    setFeeds(state, feeds) {
      state.feeds = feeds;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setCurrentTime(state, time) {
      state.current_time = time;
    },
    setAdvertisers(state, advertisers) {
      state.advertisers = advertisers;
    },
    setReviews(state, reviews) {
      state.reviews = reviews;
    },
    setCharts(state, charts) {
      state.charts = charts;
    },
    setAccessToken(state, token) {
      state.access_token = token;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },

    setRevenueSummary(state, data) {
      state.revenueSummary = data;
    },
    setAwards(state, awards) {
      state.awards = awards;
    },
    setLeagues(state, leagues) {
      state.leagues = leagues;
    },
    increaseRevenueSummary(state, data) {
      const array = ["CCOR091840", "CCOR081838", "CCOR081839"];
      if (array.includes(data.project_code)) {
        state.revenueSummary.total_revenue += data.year_value;
      } else {
        state.revenueSummary.total_revenue += data.fee_partner;
      }
      state.revenueSummary.total_sales += 1;
    },
    setStatsCherries(state, stats) {
      state.statsCherries = stats;
    },
    setQP(state, qp) {
      state.qp = qp;
    },
    setChampionsLeague(state, league) {
      state.champions_league = league;
    },
    setChampionsLeagueData(state, league_data) {
      state.champions_league_data = league_data;
    },
    setChampionsLeagueCompliance(state, league_compliance) {
      state.champions_league_compliance = league_compliance;
    },
    setChampionsLeagueReviews(state, league_reviews) {
      state.champions_league_reviews = league_reviews;
    },
    setDiamondLeague(state, league) {
      state.diamond_league = league;
    },
    setDiamondLeagueData(state, league_data) {
      state.diamond_league_data = league_data;
    },
    setDiamondLeagueCompliance(state, league_compliance) {
      state.diamond_league_compliance = league_compliance;
    },
    setDiamondLeagueReviews(state, league_reviews) {
      state.diamond_league_reviews = league_reviews;
    },
    setQualification(state, league) {
      state.qualification = league;
    },
    setQualificationData(state, league_data) {
      state.qualification_data = league_data;
    },
    setQualificationCompliance(state, league_compliance) {
      state.qualification_compliance = league_compliance;
    },
    setQualificationReviews(state, league_reviews) {
      state.qualification_reviews = league_reviews;
    },
    setLeagueExclusions(state, league_exclusions) {
      state.league_exclusions = league_exclusions;
    },
  },
  actions: {
    upsertCurrentSaleTemp(context, sale) {
      context.commit("addTempSale", sale);

      context.commit("increaseRevenueSummary", sale);
    },
    upsertCurrentSale(context, sale) {
      // console.log('upsert')
      const index = context.state.details.findIndex(
        (el) => el.sale_reference === sale.sale_reference
      );

      const contact = context.state.contacts.find(
        (el) => el.ext_sf_partner_id === sale.partner_id
      );
      if (contact) {
        // Fixed for cherries for now, later do this from 'certified_partner_settings'
        const first_name =
          sale.certified_partner_id && sale.certified_partner_id != "28835"
            ? "Certified"
            : contact.first_name;

        const last_name =
          sale.certified_partner_id && sale.certified_partner_id != "28835"
            ? "Partner"
            : contact.last_name;

        const company_name = contact.company_name;
        const alias = contact.alias;

        // console.log(
        //   sale.certified_partner_id,
        //   sale.certified_partner_id ? "Certified" : contact.first_name
        // );
        const new_sale = {
          partner_id: sale.partner_id,
          certified_partner_id: sale.certified_partner_id,
          first_name: first_name,
          last_name: last_name,
          company_name: company_name,
          alias: alias,
          sale_reference: sale.sale_reference,
          sale_closed_time: sale.sale_closed_time,
          project_code: sale.project_code,
          product_description: sale.product_description,
          fee_partner: sale.fee_partner,
          team: sale.team,
        };


        console.log('new_sale', new_sale)
        if (index > -1) {
          context.commit("updateCurrentSale", { index, sale: new_sale });
        } else {
          context.commit("addCurrentSale", new_sale);
        }
      } else {
        // console.log('partner '+sale.partner_id+' not active')
      }
    },
    upsertContact(context, contact) {
      const index = context.state.contacts.findIndex(
        (el) => el.contact_id === contact.contact_id
      );
      if (index > -1) {
        context.commit("updateContact", { index, contact });
      } else {
        context.commit("addContact", contact);
      }
    },
    setContacts(context, contacts) {
      context.commit("setContacts", contacts);
    },
    setCurrentTime(context, time) {
      context.commit("setCurrentTime", time);
    },
    setSummaryPartners(context, summary) {
      if (summary.length > 0) {
        context.dispatch("setDate", summary[0].query_date);
      }
      context.commit("setSummaryPartners", summary);
    },
    setSummaryAdvertisers(context, advertisers) {
      if (advertisers.length > 0) {
        context.dispatch("setDate", advertisers[0].query_date);
      }
      context.commit("setSummaryAdvertisers", advertisers);
    },
    setSummaryTeams(context, teams) {
      context.commit("setSummaryTeams", teams);
    },
    setDate(context, date) {
      // console.log('query date', date)
      context.commit("setDate", date);
    },
    setDetails(context, details) {
      if (details.length > 0) {
        context.dispatch("setDate", details[0].query_date);
      }
      context.commit("setDetails", details);
    },
    setDetails2(context, details) {
      if (details.length > 0) {
        context.dispatch("setDate", details[0].query_date);
      }
      const sorted_details = details.sort(context.getters.sortSaleClosed);
      context.commit("setDetails", sorted_details);
    },
    setCompliance(context, compliance) {
      context.commit("setCompliance", compliance);
    },
    setTargets(context, targets) {
      context.commit("setTargets", targets);
    },
    setFeeds(context, feeds) {
      context.commit("setFeeds", feeds);
    },
    setTeams(context, teams) {
      context.commit("setTeams", teams);
    },
    setAdvertisers(context, advertisers) {
      context.commit("setAdvertisers", advertisers);
    },
    setReviews(context, reviews) {
      context.commit("setReviews", reviews);
    },
    setCharts(context, charts) {
      context.commit("setCharts", charts);
    },
    setAccessToken(context, token) {
      context.commit("setAccessToken", token);
    },
    setTheme(context, theme) {
      context.commit("setTheme", theme);
    },
    setLoaded(context, loaded) {
      // console.log('query date', date)
      context.commit("setLoaded", loaded);
    },
    setStatsCherries(context, stats) {
      context.commit("setStatsCherries", stats);
    },
    setQP(context, qp) {
      context.commit("setQP", qp);
    },
    setAwards(context, awards) {
      context.commit("setAwards", awards);
    },
    setLeagues(context, leagues) {
      context.commit("setLeagues", leagues);
    },
    setChampionsLeague(context, league) {
      context.commit("setChampionsLeague", league);
    },
    setChampionsLeagueData(context, league_data) {
      context.commit("setChampionsLeagueData", league_data);
    },
    setChampionsLeagueCompliance(context, league_compliance) {
      context.commit("setChampionsLeagueCompliance", league_compliance);
    },
    setChampionsLeagueReviews(context, league_reviews) {
      context.commit("setChampionsLeagueReviews", league_reviews);
    },
    setDiamondLeague(context, league) {
      context.commit("setDiamondLeague", league);
    },
    setDiamondLeagueData(context, league_data) {
      context.commit("setDiamondLeagueData", league_data);
    },
    setDiamondLeagueCompliance(context, league_compliance) {
      context.commit("setDiamondLeagueCompliance", league_compliance);
    },
    setDiamondLeagueReviews(context, league_reviews) {
      context.commit("setDiamondLeagueReviews", league_reviews);
    },
    setQualification(context, league) {
      context.commit("setQualification", league);
    },
    setQualificationData(context, league_data) {
      context.commit("setQualificationData", league_data);
    },
    setQualificationCompliance(context, league_compliance) {
      context.commit("setQualificationCompliance", league_compliance);
    },
    setQualificationReviews(context, league_reviews) {
      context.commit("setQualificationReviews", league_reviews);
    },
    setLeagueExclusions(context, league_exclusions) {
      context.commit("setLeagueExclusions", league_exclusions);
    },
  },
  getters: {
    getterInitials: () => (el) => {
      const { getInitials } = shared();
      return getInitials(el.first_name, el.last_name);
    },

    mapDetails: (state, getters) => (item) => {
      item.project_code = getters.replacements(item.project_code);
      const advertiser = state.advertisers.find(
        (adv) => adv.code === item.project_code.substr(0, 4)
      );
      const compliance = getters.compliance.find(
        (cur) => item.partner_id === cur.partner_id
      );
      // console.log(foundCompliance)
      if (compliance) {
        return { ...item, ...advertiser, compliance: compliance.compliance };
      } else {
        return { ...item, ...advertiser, compliance: null };
      }
    },

    sortSaleClosed: () => (a, b) => {
      if (a.sale_closed_time < b.sale_closed_time) {
        return 1;
      }
      if (a.sale_closed_time > b.sale_closed_time) {
        return -1;
      }
      return 0;
    },

    getComplianceTeam(state, getters) {
      const retval = getters.compliance.reduce((acc, cur) => {
        const existing = acc.find((el) => el.team === cur.team);
        if (existing) {
          existing.partners.push(cur);
        } else {
          const partners = [cur];
          acc.push({ team: cur.team, partners: partners });
        }
        return acc;
      }, []);
      return retval;
    },

    getChartToday(state) {
      return state.charts.find((el) => el.chart === "today");
    },

    getFirstWeekQuarter(state) {
      return state.charts.filter((el) => el.chart === "first_week_quarter");
    },

    getChartHour(state, getters) {
      const today = getters.getChartToday;
      const hours_prev = state.charts.filter((el) => el.chart === "hour");

      const hours_today = getters.getDetails.reduce((acc, cur) => {
        const this_hour = cur.sale_closed_time.split(":")[0];
        const found = acc.find((el) => el.hour == this_hour);
        if (found) {
          found.cnt += 1;
          found.fee_partner += cur.fee_partner;
        } else {
          acc.push({
            hour: Number(this_hour),
            cnt: 1,
            fee_partner: cur.fee_partner,
            weekday_mon: today.weekday_mon,
            global_week: today.global_week,
            global_quarter: today.global_quarter,
            chart: "hour",
          });
        }
        return acc;
      }, []);
      //const details = getters.getDetails
      return [...hours_today, ...hours_prev].sort(
        (a, b) => a.global_week - b.global_week
      );
    },

    getChartWeek(state, getters) {
      const today = getters.getChartToday;
      const weeks_prev = state.charts.filter((el) => el.chart === "week");
      const week_today = getters.getDetails.reduce((acc, cur) => {
        const day = acc.find(
          (el) =>
            el.global_week == today.global_week &&
            el.weekday_mon == today.weekday_mon
        );
        if (day) {
          day.cnt += 1;
          day.fee_partner += cur.fee_partner;
        } else {
          acc.push({
            hour: 0,
            cnt: 1,
            fee_partner: cur.fee_partner,
            weekday_mon: today.weekday_mon,
            global_week: today.global_week,
            global_quarter: today.global_quarter,
            chart: "week",
          });
        }
        return acc;
      }, weeks_prev);
      //const details = getters.getDetails
      return [...week_today].sort((a, b) => a.global_week - b.global_week);
    },

    getChartQuarter(state, getters) {
      const today = getters.getChartToday;
      const quarters_prev = state.charts.filter((el) => el.chart === "quarter");
      const quarter_today = getters.getDetails.reduce((acc, cur) => {
        const day = acc.find(
          (el) =>
            el.global_week == today.global_week &&
            el.global_quarter == today.global_quarter
        );
        if (day) {
          day.cnt += 1;
          day.fee_partner += cur.fee_partner;
        } else {
          acc.push({
            hour: 0,
            cnt: 1,
            fee_partner: cur.fee_partner,
            weekday_mon: 0,
            global_week: today.global_week,
            global_quarter: today.global_quarter,
            chart: "quarter",
          });
        }
        return acc;
      }, quarters_prev);
      //const details = getters.getDetails
      return [...quarter_today].sort((a, b) => a.global_week - b.global_week);
    },

    replacements: () => (code) => {
      const check_retention_code = code
        .replace("EESS10", "RESS10")
        .replace("EESS14", "RESS14")
        .replace("EESM12", "RESM12")
        .replace("EESM13", "RESM13")
        .replace("EESP23", "RESP23")
        .replace("EEDP05", "REDP05")
        .replace("EESP28", "SESP28")
        .replace("EEDP28", "SEDP28");
      const check_servicecall_code = check_retention_code
        .replace("EESX03", "SESX03")
        .replace("EEDX03", "DESX03")
        .replace("EESX04", "HESX04");
      return check_servicecall_code;
    },

    getterBirthDays(state, getters) {
      return state.contacts
        .filter((el) => {
          if (el.birthdate) {
            return el.birthdate.slice(5, 10) === state.date.slice(5, 10);
          }
          return false;
        })
        .map((el) => {
          const p = { ...el, initials: getters.getterInitials(el) };
          return p;
        });
    },

    compliance(state) {
      return state.compliance;
    },

    getTargets(state) {
      return state.targets;
    },

    getAccessToken(state) {
      return state.access_token;
    },

    getSummaryAdvertisers: (state, getters) => (certified_partner_id) => {
      return state.summary_advertisers.map((item) => {
        const today = getters
          .totalTodayAdvertiser(certified_partner_id)
          .find((el) => el.advertiser === item.advertiser);

        const advert_detail = state.advertisers.find(
          (el) => el.code === item.advertiser
        );

        const logo_url = advert_detail.logo_url ? advert_detail.logo_url : "";
        if (today) {
          return {
            advertiser: item.advertiser,
            advertiser_name: item.advertiser_name,
            logo_url: advert_detail.logo_url,
            fee_today: today.fee_today,
            cnt_today: today.cnt_today,
            fee_this_week: item.fee_this_week + today.fee_today,
            cnt_this_week: item.cnt_this_week + today.cnt_today,
            fee_this_month: item.fee_this_month + today.fee_today,
            cnt_this_month: item.cnt_this_month + today.cnt_today,
            fee_this_year: item.fee_this_year + today.fee_today,
            cnt_this_year: item.cnt_this_year + today.cnt_today,
            fee_this_quarter: item.fee_this_quarter + today.fee_today,
            cnt_this_quarter: item.cnt_this_quarter + today.cnt_today,
            fee_this_half_year: item.fee_this_half_year + today.fee_today,
            cnt_this_half_year: item.cnt_this_half_year + today.cnt_today,
          };
        } else {
          return { ...item, logo_url: logo_url, fee_today: 0.0, cnt_today: 0 };
        }
        /// 'total_fee_week': 0.0, 'total_count_week': 0, 'total_fee_month': 0.0, 'total_count_month': 0, 'total_fee_quarter': 0.0, 'total_count_quarter': 0, 'total_fee_year': 0.0, 'total_count_year': 0 }
      });
    },

    totalTodayAdvertiser: (state, getters) => (certified_partner_id) => {
      let retval = state.details.reduce((acc, cur) => {
        // console.log("proj", cur.project_code.substr(0, 5));
        let adv = acc.find(
          (adv) =>
            adv.advertiser ===
            getters.replacements(cur.project_code).substr(0, 4)
        );

        let fee = 0;
        let cnt = 0;
        // console.log("cp id ", certified_partner_id, cur.certified_partner_id);
        if (
          !certified_partner_id ||
          certified_partner_id === cur.certified_partner_id
        ) {
          fee = cur.fee_partner;
          cnt = 1;
        }

        if (adv) {
          adv.fee_today += fee;
          adv.cnt_today += cnt;
        } else {
          acc.push({
            advertiser: getters.replacements(cur.project_code).substr(0, 4),
            fee_today: fee,
            cnt_today: cnt,
          });
        }

        return acc;
      }, []);
      return retval;
    },

    getSummaryTeams(state, getters) {
      return state.summary_teams.map((item) => {
        const today = getters.totalTodayTeam.find(
          (el) => el.team === item.team
        );
        if (today) {
          return {
            team: item.team,
            fee_today: today.fee_today,
            cnt_today: today.cnt_today,
            fee_this_week: item.fee_this_week + today.fee_today,
            cnt_this_week: item.cnt_this_week + today.cnt_today,
            fee_this_month: item.fee_this_month + today.fee_today,
            cnt_this_month: item.cnt_this_month + today.cnt_today,
            fee_this_year: item.fee_this_year + today.fee_today,
            cnt_this_year: item.cnt_this_year + today.cnt_today,
            fee_this_quarter: item.fee_this_quarter + today.fee_today,
            cnt_this_quarter: item.cnt_this_quarter + today.cnt_today,
            fee_this_half_year: item.fee_this_half_year + today.fee_today,
            cnt_this_half_year: item.cnt_this_half_year + today.cnt_today,
          };
        } else {
          return { ...item, fee_today: 0.0, cnt_today: 0 };
        }

        /// 'total_fee_week': 0.0, 'total_count_week': 0, 'total_fee_month': 0.0, 'total_count_month': 0, 'total_fee_quarter': 0.0, 'total_count_quarter': 0, 'total_fee_year': 0.0, 'total_count_year': 0 }
      });
    },

    totalTodayTeam(state) {
      return state.details.reduce((acc, cur) => {
        let team = acc.find((el) => el.team === cur.team);
        if (cur.certified_partner_id == "28835") {
          team = acc.find((el) => el.team === "Cherries");
        }
        if (team) {
          team.fee_today += cur.fee_partner;
          team.cnt_today += 1;
        } else {
          acc.push({
            team: cur.certified_partner_id == "28835" ? "Cherries" : cur.team,
            fee_today: cur.fee_partner,
            cnt_today: 1,
          });
        }
        return acc;
      }, []);
    },

    formatSummaryPartnerBig(state) {
      return state.summary_partners.reduce((acc, cur) => {
        acc.push({
          partner_id: cur.partner_id,
          first_name: cur.first_name,
          last_name: cur.last_name,
          company_name: cur.company_name,
          alias: cur.alias,
          team: cur.team,
          fee_partner_today: 0.0,
          cnt_partner_today: 0,
          fee_partner_this_quarter: cur.fee_partner_this_quarter,
          cnt_partner_this_quarter: cur.cnt_partner_this_quarter,
          fee_partner_this_week: cur.fee_partner_this_week,
          cnt_partner_this_week: cur.cnt_partner_this_week,
          certified_partner_id: cur.certified_partner_id,
        });
        // } else {
        //   // console.log(cur.certified_partner_id, typeof(cur.certified_partner_id))
        // }
        return acc;
      }, []);
    },
    formatTodayPartnerBig(_, getters) {
      return getters.getDetails.reduce((acc, cur) => {
        //if (!cur.certified_partner_id) {
        console.log(cur.alias, cur.partner_id, cur.first_name)
        acc.push({
          partner_id: cur.partner_id,
          first_name: cur.first_name,
          last_name: cur.last_name,
          company_name: cur.company_name,
          alias: cur.alias,
          team: cur.team,
          fee_partner_today: cur.fee_partner,
          cnt_partner_today: 1,
          fee_partner_this_quarter: cur.fee_partner,
          cnt_partner_this_quarter: 1,
          fee_partner_this_week: cur.fee_partner,
          cnt_partner_this_week: 1,
          certified_partner_id: cur.certified_partner_id,
        });
        return acc;
      }, []);
    },

    totalTodayFiltered: (state) => (team) => {
      return state.details.reduce(
        (acc, cur) => {
          if (cur.team == team) {
            acc.fee_today += cur.fee_partner;
            acc.cnt_today += 1;
          }
          return acc;
        },
        { fee_today: 0.0, cnt_today: 0 }
      );
    },

    totalToday: (state) => (certified_partner_id) => {
      return state.details.reduce(
        (acc, cur) => {
          if (
            !certified_partner_id ||
            cur.certified_partner_id == certified_partner_id
          ) {
            acc.fee_today += cur.fee_partner;
            acc.cnt_today += 1;
          }
          return acc;
        },
        { fee_today: 0.0, cnt_today: 0 }
      );
    },

    totalAll: (state, getters) => (certified_partner_id) => {
      const total = getters.totalToday(certified_partner_id);
      const summ = state.summary_teams.reduce(
        (acc, cur) => {
          (acc.fee_this_year += cur.fee_this_year),
            (acc.cnt_this_year += cur.cnt_this_year),
            (acc.fee_this_quarter += cur.fee_this_quarter),
            (acc.cnt_this_quarter += cur.cnt_this_quarter),
            (acc.fee_this_month += cur.fee_this_month),
            (acc.cnt_this_month += cur.cnt_this_month),
            (acc.fee_this_week += cur.fee_this_week),
            (acc.cnt_this_week += cur.cnt_this_week),
            (acc.fee_this_half_year += cur.fee_this_half_year),
            (acc.cnt_this_half_year += cur.cnt_this_half_year);
          return acc;
        },
        {
          fee_this_year: total.fee_today,
          cnt_this_year: total.cnt_today,
          fee_this_quarter: total.fee_today,
          cnt_this_quarter: total.cnt_today,
          fee_this_month: total.fee_today,
          cnt_this_month: total.cnt_today,
          fee_this_week: total.fee_today,
          cnt_this_week: total.cnt_today,
          fee_this_half_year: total.fee_today,
          cnt_this_half_year: total.cnt_today,
        }
      );
      return { ...summ, ...total };
    },

    totalAllFiltered: (state, getters) => (team) => {
      const total = getters.totalTodayFiltered(team);
      const summ = state.summary_teams
        .filter((el) => el.team == team)
        .reduce(
          (acc, cur) => {
            (acc.fee_this_year += cur.fee_this_year),
              (acc.cnt_this_year += cur.cnt_this_year),
              (acc.fee_this_quarter += cur.fee_this_quarter),
              (acc.cnt_this_quarter += cur.cnt_this_quarter),
              (acc.fee_this_month += cur.fee_this_month),
              (acc.cnt_this_month += cur.cnt_this_month),
              (acc.fee_this_week += cur.fee_this_week),
              (acc.cnt_this_week += cur.cnt_this_week),
              (acc.fee_this_half_year += cur.fee_this_half_year),
              (acc.cnt_this_half_year += cur.cnt_this_half_year);
            return acc;
          },
          {
            fee_this_year: total.fee_today,
            cnt_this_year: total.cnt_today,
            fee_this_quarter: total.fee_today,
            cnt_this_quarter: total.cnt_today,
            fee_this_month: total.fee_today,
            cnt_this_month: total.cnt_today,
            fee_this_week: total.fee_today,
            cnt_this_week: total.cnt_today,
            fee_this_half_year: total.fee_today,
            cnt_this_half_year: total.cnt_today,
          }
        );
      return { ...summ, ...getters.totalToday };
    },

    getDetails(state, getters) {
      // const d =  new Date()
      // console.log('getDetailsStart', d.toLocaleString() + ' ' + d.getMilliseconds())

      // Allow CP rollback
      // const retval = state.details.filter((el) => el.certified_partner_id == null).map(getters.mapDetails)
      const retval = state.details.map(getters.mapDetails);
      // const e =  new Date()
      // console.log('getDetailsEnd', e.toLocaleString() + ' ' + e.getMilliseconds())
      return retval;
    },
    getDetails15(state, getters) {
      // const d =  new Date()
      // console.log('getDetailsNewerStart', d.toLocaleString() + ' ' + d.getMilliseconds())

      // Allow CP rollback
      // const retval =  state.details.filter((el) => el.certified_partner_id == null).slice(0,15).map(getters.mapDetails)
      const retval = state.details.slice(0, 15).map(getters.mapDetails);
      // const e  =  new Date()
      // console.log('getDetailsNewerEnd', e.toLocaleString() + ' ' + e.getMilliseconds())
      return retval;
    },
    getDetails15New: (state, getters) => (certified_partner_id) => {
      // Allow CP rollback
      if (certified_partner_id) {
        return state.details
          .filter((el) => el.certified_partner_id == certified_partner_id)
          .slice(0, 15)
          .map(getters.mapDetails);
      } else {
        return state.details.slice(0, 15).map(getters.mapDetails);
      }
    },
    getBirthDays(state, getters) {
      //console.log(state.contacts)
      return state.contacts
        .filter((el) => {
          if (
            el.birthdate &&
            el.ext_sf_test_record.toLowerCase() == "false" &&
            (el.ext_sf_certified_partner_id == null ||
              el.ext_sf_certified_partner_id == "28835")
          ) {
            return el.birthdate.slice(5, 10) === state.date.slice(5, 10);
          }
          return false;
        })
        .map((el) => {
          const p = { ...el, initials: getters.getterInitials(el) };
          return p;
        });
    },
    partnerBig(state, getters) {
      const d = new Date();
      console.log(
        "partnerBig2Start",
        d.toLocaleString() + " " + d.getMilliseconds()
      );
      let val = [
        ...getters.formatTodayPartnerBig,
        ...getters.formatSummaryPartnerBig,
      ];
      // console.log(val);
      val = val
        .reduce((acc, cur) => {
          const partnerData = acc.find(
            (item) => item.partner_id === cur.partner_id
          );

          if (partnerData) {
            (partnerData.fee_partner_today += cur.fee_partner_today),
              (partnerData.cnt_partner_today += cur.cnt_partner_today),
              (partnerData.fee_partner_this_quarter +=
                cur.fee_partner_this_quarter),
              (partnerData.cnt_partner_this_quarter +=
                cur.cnt_partner_this_quarter),
              (partnerData.fee_partner_this_week += cur.fee_partner_this_week),
              (partnerData.cnt_partner_this_week += cur.cnt_partner_this_week);
          } else {
            const foundCompliance = getters.compliance.find(
              (item) => item.partner_id === cur.partner_id
            );
            const foundAward = state.awards.find(
              (item) => item.user_id === cur.partner_id && item.max.target !== 99000000
            )
            const foundLeagues = state.leagues.find(
              (item) => item.partner.user_id === cur.partner_id
            )
            const compliance = foundCompliance ? foundCompliance.compliance : null
            const award = foundAward ? foundAward.max.target/1000 + 'k' : null

            let champion = false
            let diamond = false

            if (foundLeagues) {
              champion = foundLeagues.trips.indexOf('champions_trip') >= 0
              diamond = foundLeagues.trips.indexOf('diamond_trip') >= 0
            }


            acc.push({ ...cur, compliance: compliance, award: award, champion: champion, diamond: diamond });
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.fee_partner_this_week < b.fee_partner_this_week) {
            return 1;
          }
          if (a.fee_partner_this_week > b.fee_partner_this_week) {
            return -1;
          }
          return 0;
        });
      const e = new Date();
      console.log(
        "partnerBig2End",
        e.toLocaleString() + " " + e.getMilliseconds()
      );
      return val;
    },
    partnerBig2(state, getters) {
      const d = new Date();
      console.log(
        "partnerBig2Start",
        d.toLocaleString() + " " + d.getMilliseconds()
      );
      const val = [
        ...getters.formatTodayPartnerBig,
        ...getters.formatSummaryPartnerBig,
      ]
        .reduce((acc, cur) => {
          const partnerData = acc[cur.partner_id];
          if (partnerData) {
            (partnerData.fee_partner_today += cur.fee_partner_today),
              (partnerData.cnt_partner_today += cur.cnt_partner_today),
              (partnerData.fee_partner_this_quarter +=
                cur.fee_partner_this_quarter),
              (partnerData.cnt_partner_this_quarter +=
                cur.cnt_partner_this_quarter),
              (partnerData.fee_partner_this_week += cur.fee_partner_this_week),
              (partnerData.cnt_partner_this_week += cur.cnt_partner_this_week);
          } else {
            const foundCompliance = getters.compliance.find(
              (item) => item.partner_id === cur.partner_id
            );
            if (foundCompliance) {
              acc[cur.partner_id] = {
                ...cur,
                compliance: foundCompliance.compliance,
              };
            } else {
              acc[cur.partner_id] = { ...cur, compliance: null };
            }
          }
          return acc;
        }, {})
        .sort((a, b) => {
          if (a.fee_partner_this_week < b.fee_partner_this_week) {
            return 1;
          }
          if (a.fee_partner_this_week > b.fee_partner_this_week) {
            return -1;
          }
          return 0;
        });
      const e = new Date();
      console.log(
        "partnerBig2End",
        e.toLocaleString() + " " + e.getMilliseconds()
      );
      return val;
    },
    getTargetsSum(state) {
      return state.targets.reduce(
        (acc, cur) => {
          // only if team filled, else advertiser targets
          if (cur.team) {
            (acc.year_target += cur.year_target),
              (acc.quarter_target += cur.quarter_target),
              (acc.month_target += cur.month_target),
              (acc.week_target += cur.week_target),
              (acc.half_year_target += cur.half_year_target);
          }
          return acc;
        },
        {
          year_target: 0.0,
          quarter_target: 0.0,
          month_target: 0.0,
          week_target: 0.0,
          half_year_target: 0.0,
        }
      );
    },
    getDate(state) {
      return state.date;
    },
  },
  modules: {},
});
